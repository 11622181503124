import React, {
  useState,
  useContext,
  cloneElement,
  createContext,
} from "react";
import Modal from "react-modal";
import "./modal.scss";
const callAll = (...fns) => (...args) => fns.forEach((fn) => fn && fn(...args));

const ModalContext = createContext({});

function ModalPopup(props) {
  const [isOpen, setIsOpen] = useState(false);
  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />;
}

function ModalDismissButton({ children: child }) {
  const [, setIsOpen] = useContext(ModalContext);

  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  });
}

function ModalOpenButton({ children: child }) {
  const [, setIsOpen] = useContext(ModalContext);
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(true), child.props.onClick),
  });
}

function ModalContentsBase(props) {
  const [isOpen, setIsOpen] = useContext(ModalContext);
  console.log(useContext(ModalContext));
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className="subscribe-to-newsletter-modal app-modal"
      overlayClassName="subscribe-to-newsletter-overlay"
      onRequestClose={() => setIsOpen(false)}
    >
      {props.children}
    </Modal>
  );
}

function ModalContents({ title, children, ...props }) {
  return (
    <ModalContentsBase {...props}>
      <div className="inner-modal-box" style={{ padding: "20px" }}>
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <ModalDismissButton>
            <i
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                color: "#fff",
                transform: "translate(25px, -25px)",
                cursor: "pointer",
                fontSize: "24px",
                fontFamily: "sans-serif",
                fontStyle: "normal",
                width: "50px",
                height: "50px",
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                "background-color": "#00b6ed",
                "border-radius": "50%",
              }}
            >
              x
            </i>
          </ModalDismissButton>
        </div>
        {children}
      </div>
    </ModalContentsBase>
  );
}

export { ModalPopup, ModalDismissButton, ModalOpenButton, ModalContents };
