import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./apps.scss";
import Linked from "../../utils/linkedItem";
import { AmazeeIOButton } from "../../components/button/button";
import {
  getPage,
  TypeAppFrameworksPage,
  TypeAppFrameworksQueryResult,
} from "../../utils/queries/apps_frameworks";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import {
  ModalPopup,
  ModalContents,
  ModalOpenButton,
} from "../../components/modal";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruption } from "../../templates/resources/customers";

const query = graphql`
  {
    allPrismicAppsFrameworks {
      edges {
        node {
          data {
            cta_button_text
            cta_title
            cta_text
            page_meta_description
            section_subtitle1
            section_title1
            title
            meta_title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
            apps {
              title1
              description
              popup_description {
                html
                raw
                text
              }
              image {
                alt
                url
              }
            }
            cta_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            page_meta_thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  }
`;

const AppsFrameworksQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeAppFrameworksQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicAppsFrameworks",
    true
  ) as TypeAppFrameworksPage;
  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="apps-frameworks">
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="app-framework-description" className="pt-10 pb-0">
          <div className="container">
            <div className="row flex justify-center text-top">
              <div className="col-12 col-lg-10 text-center">
                <h3>{RichTextRender(result.section_title1)}</h3>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <h6>{RichTextRender(result.section_subtitle1)}</h6>
              </div>
            </div>

            <div className="lg:grid gap-5 grid-cols-3 col-lg-10 mx-auto py-6">
              {result.apps.map((app, index) => (
                <div key={index} className="app">
                  <div className="flex justify-between items-center pt-2 pb-4">
                    <div className="app-title">{app.title1}</div>
                    <img
                      className="w-10 h-10"
                      src={app.image.url}
                      alt={app.image.alt}
                    />
                  </div>
                  <div className="app-description">{app.description}</div>
                  <ModalPopup>
                    <ModalOpenButton>
                      <AmazeeIOButton classes="inverted inline-block mt-4 cursor-pointer">
                        Read More
                      </AmazeeIOButton>
                    </ModalOpenButton>
                    <ModalContents title="app-popup">
                      <div className="flex justify-between items-center py-2 mb-4">
                        <div className="app-title">{app.title1}</div>
                        <img
                          className="w-12 h-12"
                          src={app.image.url}
                          alt={app.image.alt}
                        />
                      </div>
                      <div className="app-description">{app.description}</div>
                      <div className="app-popup-description">
                        {RichTextRender(app.popup_description)}
                      </div>
                      <Linked
                        link_to={{
                          document: {
                            data: { url: "/get-in-touch", is_external: false },
                          },
                        }}
                      >
                        <AmazeeIOButton classes="inverted inline-block mt-4">
                          Contact us to configure a Test Environment
                        </AmazeeIOButton>
                      </Linked>
                    </ModalContents>
                  </ModalPopup>
                </div>
              ))}
            </div>
          </div>
        </section>

        <ResourceInteruption
          cta_title={result.cta_title}
          cta_text={result.cta_text}
          cta_button_text={result.cta_button_text}
          second_cta_button_text={""}
          cta_link={result.cta_link}
          second_cta_button_link={null}
          topMargin={false}
        />
      </div>
    </AmazeePage>
  );
};

const StaticAppsFrameworksQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <AppsFrameworksQueryPage location={location} data={data} />
      )}
    />
  );
};
export default StaticAppsFrameworksQueryPage;
