import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";

export type TypeAppFrameworksApps = {
  image: PrismicImage;
  title1: string;
  description: string;
  popup_description: PrismicRichText;
};

export type TypeAppFrameworksPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;

  section_title: string;
  section_title1: string;
  section_title2: string;

  section_subtitle: PrismicRichText;
  section_subtitle1: PrismicRichText;
  hero_image: PrismicImage;

  cta_title: string;
  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;

  apps: TypeAppFrameworksApps[];

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeAppFrameworksQueryResult = {
  allPrismicAppsFrameworks: {
    edges: Array<{
      node: { data: TypeAppFrameworksPage };
    }>;
  };
};

export const getPage = (
  data: TypeAppFrameworksQueryResult
): TypeAppFrameworksPage => {
  return data.allPrismicAppsFrameworks.edges[0].node.data;
};
